import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/user/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-edit-data/:type',
    loadChildren: () => import('./add-edit-data/add-edit-data.module').then( m => m.AddEditDataPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/list.module').then(m => m.ListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer/:id',
    loadChildren: () => import('./customer/detail/detail.module').then( m => m.DetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'list',
    loadChildren: () => import('./customer/list.module').then( m => m.ListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports/:type/:year',
    loadChildren: () => import('./reports/detail/detail.module').then( m => m.DetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports/:type/:month/:year',
    loadChildren: () => import('./reports/detail/detail.module').then( m => m.DetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'employees',
    loadChildren: () => import('./employees/employees.module').then( m => m.EmployeesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'expenses',
    loadChildren: () => import('./expenses/expenses.module').then( m => m.ExpensesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'expenses/:title',
    loadChildren: () => import('./expenses/detail/detail.module').then( m => m.DetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'imports',
    loadChildren: () => import('./imports/imports.module').then( m => m.ImportsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
