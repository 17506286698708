import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './shared/authentication-service';

// import { MenuController } from '@ionic/core/components';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    public router: Router,
    // public menuCtrl: MenuController,
    public authService: AuthenticationService,
     ) {}

  changePage(page){
    // this.menuCtrl.close();
    this.router.navigate([page], {replaceUrl: true});
  }

}
