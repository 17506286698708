export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAMY3-vwI4IIGtK4ROYpp2DLGb3MgECx0g",
    authDomain: "cashflow-8debc.firebaseapp.com",
    projectId: "cashflow-8debc",
    storageBucket: "cashflow-8debc.appspot.com",
    messagingSenderId: "466108495284",
    appId: "1:466108495284:web:a51c7e228d01c2319a6ee1"
  },
};
